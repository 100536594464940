import { useNavigate } from 'react-router-dom';
import { tokens } from "../theme";
import Logo from "../public/logo.png";

import { color } from "@mui/system";

import { AppBar, Box, Toolbar, useTheme, Typography, IconButton } from '@mui/material';
import { Assessment } from '@mui/icons-material';

import Login from './LoginMenu';

const Banner = () => {
  let navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <AppBar position="fixed" sx={{ zIndex: 2, height: "48px", py: 0.5, pl: 2, background: `linear-gradient(to right bottom, ${colors.primary[500]}, ${colors.primary[500]})` }} >
      <Box sx={{ display: 'flex' }} >
        <IconButton onClick={() => { navigate("/") }} size="large" edge="start" color="inherit" aria-label="menu" sx={{ width: "95px", height: "36px" }} >
        <img
                  alt=""
                  width="95px"
                  height="35px"
                  src={Logo} 
                  
                  //style={{ cursor: "pointer", borderRadius: "50%" }}
                />
        </IconButton>
        <Typography variant="h5" flexGrow={0} sx={{ ml: 1, pt:0.5 }} >Embedded Reports</Typography>
        <Login/>
      </Box>
      

    </AppBar>
  )
}

export default Banner;